[x-cloak] {
  display: none;
}

body {
  @apply font-sans;
}

.skip-link {
  @apply absolute block overflow-hidden;

  z-index: 999;
  clip: rect(1px, 1px, 1px, 1px);
  height: theme('spacing.px');
  width: theme('spacing.px');

  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    width: auto;
  }
}

.stretched-link::after {
  @apply absolute inset-0 z-10 bg-transparent pointer-events-auto;

  content: "";
}

.body-scroll-lock {
  touch-action: none;
  overflow: hidden;
}
